<template>
  <div>
    <v-card outlined>
      <v-toolbar flat dense dark color="primary">
        <v-toolbar-title>Add Node</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="
          var valueSend = [];
          sendToParentComponent(valueSend)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          v-model="vDataTable.selected"
          :headers="headers"
          :items="vDataTable.data"
          :search="vDataTable.search"
          show-select
          fixed-header
          height="400"
          :page.sync="vDataTable.options.page"
          :itemsPerPage.sync="vDataTable.options.itemsPerPage"
          :options.sync="vDataTable.options"
          :footer-props="{
            itemsPerPageText: $t('itemsPerPageText'),
            itemsPerPageAllText: $t('itemsPerPageAllText'),
            showFirstLastPage: true,
            showCurrentPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
          item-key="node_skey"
          class="elevation-1">
          <template v-slot:header.data-table-select="{ on, props }">
            <tr>
              <td>
                <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
              </td>
              <td v-if="vDataTable.selected.length > 0">
                {{vDataTable.selected.length}}
              </td>
            </tr>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
            </v-toolbar>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)" ></v-simple-checkbox>
          </template>
          <template v-slot:no-data>
            <v-alert type="info">
              {{$t('noData')}}
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert type="warning">
              {{stringFormat($t('searchNotFound'),vDataTable.search)}}
            </v-alert>
          </template>
          <template v-slot:footer>
            <tr class="sticky-table-footer" style="position: absolute">
              <td>Selected({{vDataTable.selected.length}})s</td> 
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
          @click="
            var valueSend = [];
            valueSend.actionReturn = 'OK'
            valueSend.dataSelected = vDataTable.selected
            sendToParentComponent(valueSend)">
        {{$t('ok')}}
        </v-btn>
        <v-divider class="mx-1" inset vertical></v-divider>
        <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
          @click="
            var valueSend = [];
            sendToParentComponent(valueSend)">
        {{$t('cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  export default {
    props: ['receiveVar'],
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        vDataTable: {
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
      }
    },
    created: function () {
    },
    computed: {
      headers () {
        return [
          { text: 'Node Type', value: 'node_type_desc', align: 'right' },
          { text: 'Node Name', value: 'node_name', align: 'left' },
          { text: 'Window Name', value: 'win_name', align: 'left' },
        ]
      }
    },
    methods: {
      sendToParentComponent (value) {
        this.selected = []
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      retrieveNodeDef (appName) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveNodeDef' + process.env.VUE_APP_DATABASE_TYPE, {
          app_name: appName
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>