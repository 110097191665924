<template>
  <div class="tree3">
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-card-title>
        <v-text-field
          v-model="searchword"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn icon slot="activator" class="mx-0" @click="searchRoute()">
          <v-icon color="green">search</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-tree ref='tree1' :canDeleteRoot="false" :data='dataRoute' :draggable='true' @drag-node-end='dragNode' :tpl='tpl' :halfcheck='true' :multiple="true"/>
    <v-card>
      <v-dialog v-model="dialog.dialogMessage" persistent width="500" eager>
        <v-card>
          <p class="text-md-center"><v-icon style="font-size: 100px;" v-bind:style="{ 'color': iconColor }">{{iconText}}</v-icon></p>
          <v-card-title class="headline">
              <h1 class="headline mb-0"><b><p class="text-md-center">{{titleMessage}}</p></b></h1>
          </v-card-title>
          <v-card-text>
              <h3 class="headline mb-0"><b><p class="text-md-center">{{bodyMessage}}</p></b></h3>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="dialog.dialogMessage = false"
            >
            OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.dialogAddNodeRoute" width="800" eager>
        <NavRouteWindowAddNodeRoute ref="addNodeRoute"
          :receiveVar="sendVar" @eventReturn="getVarReturn">
        </NavRouteWindowAddNodeRoute>
      </v-dialog>
      <v-dialog v-model="dialog.dialogRouteFromNode" width="800" scrollable eager>
        <NavRouteWindowRouteFromNode ref="routeFromNode"
          :receiveVar="sendVar" @eventReturn="getVarReturn">
        </NavRouteWindowRouteFromNode>
      </v-dialog>
      <v-dialog v-model="dialog.dialogEditSecurity" width="800" fullscreen eager>
        <NavWindowObjectSecurity ref="objectSecurity"
          :receiveVar="sendVar" @eventReturn="getVarReturn">
        </NavWindowObjectSecurity>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
  import NavRouteWindowAddNodeRoute from '@/components/navigation/NavRouteWindowAddNodeRoute'
  import NavRouteWindowRouteFromNode from '@/components/navigation/NavRouteWindowRouteFromNode'
  import NavWindowObjectSecurity from '@/components/navigation/NavWindowObjectSecurity'
  
  export default {
    components: {
      NavRouteWindowAddNodeRoute,
      NavRouteWindowRouteFromNode,
      NavWindowObjectSecurity
    },
    name: 'navigation',
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        appName: process.env.VUE_APP_DB_APP_NAME,
        dialog: {
          dialogProgress: false,
          dialogMessage: false,
          dialogAddNodeRoute: false,
          dialogRouteFromNode: false,
          dialogEditSecurity: false,
        },
        sendVar: [],
        appSkey: -1,
        nodeSkey: 0,
        iconColor: '',
        iconText: 'message',
        titleMessage: '',
        bodyMessage: '',
        searchTextNode: '',
        searchTextRouteFromNode: '',
        searchword: '',
        paginationNode: {
          sortBy: 'node_name',
          rowsPerPage: 10
        },
        paginationRouteFromNode: {
          rowsPerPage: 10
        },
        addFromNodeSkey: 0,
        deleteFromNode: [],
        deleteFromParent: [],
        deleteFromIndex: 0,
        selectedNode: [],
        dataRoute: [],
        dataNode: [],
        dataRouteFromNode: [],
        dataObjectSecurity: [],
        headerNode: [
          { text: 'Node Type', value: 'node_type_desc', align: 'right' },
          { text: 'Node Name', value: 'node_name', align: 'left' },
          { text: 'Component Name', value: 'win_name', align: 'left' },
        ],
        headerRouteFromNode: [
          { text: 'Node Name', value: 'node_name', align: 'left' },
          { text: 'Sequence', value: 'sort_seq', align: 'left' },
        ],
        title: process.env.Title,
      }
    },
    async created () {
      await this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
      this.retrieveNodeDef(this.appName)
    },
    mounted () {
    },
    methods: {
      getVarReturn (value) {
        switch (value.childSend) {
          case 'NavRouteWindowAddNodeRoute':
            this.dialog.dialogAddNodeRoute = false
            switch (value.actionReturn) {
              case 'OK':
                this.addRouteNode(this.appSkey, value.dataSelected)
            }
            break
          case 'NavRouteWindowRouteFromNode':
            this.dialog.dialogRouteFromNode = false
            switch (value.actionReturn) {
              case 'OK':
                this.updateRouteFromNode(this.appSkey, value.dataSelected)
            }
            break
          case 'NavWindowObjectSecurity':
            this.dialog.dialogEditSecurity = false
            break
        }
        this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
      },
      getNestedChildren: function (arr, parent) {
        var out = []
        for (var i in arr) {
          if (arr[i].from_current_skey === parent) {
            var items = this.getNestedChildren(arr, arr[i].current_skey)
            arr[i].title = arr[i].node_name
            if (items.length >= 0) {
              arr[i].expanded = true
              arr[i].children = items
            } else {
              arr[i].expanded = false
            }
            out.push(arr[i])
          }
        }
        return out
      },
      tpl (...args) {
        let {0: node, 2: parent, 3: index} = args
        let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
        if (node.searched) titleClass += ' node-searched'
        if (node.node_type.trim() === 'W') {
          return <span>
             <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
               this.$refs.tree1.nodeSelected(node)
             }}></span>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.sendVar.nodeSkey = node['node_skey']; this.dialog.dialogAddNodeRoute = true; var child = this.$refs.addNodeRoute; child.retrieveNodeDef(this.appName) }}>
               <v-icon color="blue">add</v-icon>
             </v-btn>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.deleteRouteNode(this.appSkey, node, parent, index) }}>
               <v-icon color="red">delete</v-icon>
             </v-btn>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.sendVar.nodeSkey = parent['node_skey']; this.dialog.dialogRouteFromNode = true; var child = this.$refs.routeFromNode; child.retrieveNodeRouteFromNode(this.appSkey, parent['node_skey']) }}>
               <v-icon color="green">list</v-icon>
             </v-btn>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.sendVar.nodeSkey = node['node_skey']; this.dialog.dialogEditSecurity = true; var child = this.$refs.objectSecurity; child.retrieveComponentObjectSecurity(this.appSkey, node['node_skey']) }}>
               <v-icon color="yellow">lock</v-icon>
             </v-btn>
          </span>
        } else {
          return <span>
             <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
               this.$refs.tree1.nodeSelected(node)
             }}></span>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.deleteRouteNode(this.appSkey, node, parent, index) }}>
               <v-icon color="red">delete</v-icon>
             </v-btn>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.sendVar.nodeSkey = parent['node_skey']; this.dialog.dialogRouteFromNode = true; var child = this.$refs.routeFromNode; child.retrieveNodeRouteFromNode(this.appSkey, parent['node_skey']) }}>
               <v-icon color="green">list</v-icon>
             </v-btn>
             <v-btn icon slot="activator" class="mx-0" onClick={() => { this.sendVar.nodeSkey = node['node_skey']; this.dialog.dialogEditSecurity = true; var child = this.$refs.objectSecurity; child.retrieveComponentObjectSecurity(this.appSkey, node['node_skey']) }}>
               <v-icon color="yellow">lock</v-icon>
             </v-btn>
          </span>
        }
      },
      dragNode (node) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/updateNodeRouteDragTarget' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: this.appSkey,
          drag_from_node_skey: node.dragNode['from_node_skey'],
          drag_to_node_skey: node.dragNode['node_skey'],
          target_from_node_skey: node.targetNode['from_node_skey'],
          target_to_node_skey: node.targetNode['node_skey'],
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
              this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      searchRoute () {
        this.$refs.tree1.searchNodes(this.searchword)
      },
      addRouteNode (appSkey, toNode) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/addNodeRoute' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: appSkey,
          from_node_skey: this.sendVar.nodeSkey,
          to_node: toNode
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
              this.selectedNode = []
              this.dialog.dialogAddNodeRoute = false
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      updateRouteFromNode (appSkey, data) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/updateNodeRoute' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: appSkey,
          route_from_node: data
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
              this.dialog.dialogRouteFromNode = false
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      deleteRouteNode (appSkey, node, parent, index) {
        this.deleteFromNode = node
        this.deleteFromParent = parent
        this.deleteFromIndex = index
        this.$swal({
          text: 'Do you want to delete Node?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.axios.post(process.env.VUE_APP_API + '/navigation/deleteNodeRoute' + process.env.VUE_APP_DATABASE_TYPE, {
              app_skey: appSkey,
              from_node_skey: parent === null ? 0 : parent['node_skey'],
              to_node_skey: node['node_skey']
            })
              .then(response => {
                if (response.data.isSuccess) {
                  this.$refs.tree1.delNode(node, parent, index)
                } else {
                  this.$swal({type: 'error', title: response.data.reasonText})
                }
              })
              .catch(e => {
                this.$swal({type: 'error', title: e.message})
              })
          }
        })
      },
      showDialog (titleMessage, bodyMessage, typeMessage, colorMessage) {
        this.iconColor = colorMessage
        this.iconText = typeMessage
        this.dialog.dialogMessage = true
        this.titleMessage = titleMessage
        this.bodyMessage = bodyMessage
      },
      async retrieveNodeRoute (appName) {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        await this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveNodeRoute' + process.env.VUE_APP_DATABASE_TYPE, {
          app_name: appName
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.appSkey = response.data.data.length > 0 ? response.data.data[0]['app_skey'] : -1
              this.dataRoute = this.getNestedChildren(response.data.data, 0)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
        this.dialog.dialogProgress = false
      },
      retrieveNodeDef (appName) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveNodeDef' + process.env.VUE_APP_DATABASE_TYPE, {
          app_name: appName
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dataNode = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      retrieveObjectSecurity (appSkey, nodeSkey) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveComponentObjectSecurity' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: appSkey,
          node_skey: nodeSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dataObjectSecurity = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      toggleAllNode () {
        if (this.selectedNode.length) this.selectedNode = []
        else this.selectedNode = this.dataRoute.slice()
      },
      changeSortNode (column) {
        if (this.paginationRoute.sortBy === column) {
          this.paginationRoute.descending = !this.paginationRoute.descending
        } else {
          this.paginationRoute.sortBy = column
          this.paginationRoute.descending = false
        }
      },
      changeSortRouteFromNode (column) {
        if (this.paginationRouteFromNode.sortBy === column) {
          this.paginationRouteFromNode.descending = !this.paginationRouteFromNode.Routedescending
        } else {
          this.paginationRouteFromNode.sortBy = column
          this.paginationRouteFromNode.descending = false
        }
      },
    }
  }
</script>
<style>
.tree3{
  float: left;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 800px;
}
.treebtn1{
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 1px 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: rgb(148, 147, 147);
}
.treebtn2{
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 5px;
  color: rgb(97, 97, 97);
}
.treebtn3{
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 5px;
  color: rgb(63, 63, 63);
}
.tree-search-input{
  width: 70%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  border:1px solid #ccc;
}
.tree-search-btn{
  width: 25%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: rgb(218, 218, 218);
  border:1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
