<template>
  <v-card outlined>
    <v-toolbar flat dense dark color="primary">
      <v-toolbar-title>Security</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="
        var valueSend = [];
        sendToParentComponent(valueSend)">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        v-model="vDataTable.selected"
        :headers="headers"
        :items="vDataTable.data"
        :search="vDataTable.search"
        fixed-header
        hide-default-footer
        height="200"
        :page.sync="vDataTable.options.page"
        :itemsPerPage.sync="vDataTable.options.itemsPerPage"
        :options.sync="vDataTable.options"
        :footer-props="{
          itemsPerPageText: $t('itemsPerPageText'),
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          showFirstLastPage: true,
          showCurrentPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        item-key="node_skey"
        class="elevation-1">
        <template v-slot:item.access_ind="{ item }">
          <v-select autocomplete dense
            :items="accessTypeList"
            item-value="code"
            item-text="description"
            v-model="item.access_ind"
            @change="editItem(item)">
          </v-select>
        </template>
        <template v-slot:no-data>
          <v-alert type="info">
            {{$t('noData')}}
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert type="warning">
            {{stringFormat($t('searchNotFound'),vDataTable.search)}}
          </v-alert>
        </template>
      </v-data-table>
      <v-tabs slider-color="blue">
        <v-tab ripple>
          User
        </v-tab>
        <v-tab ripple>
          Group
        </v-tab>
        <v-tab-item eager>
          <v-divider></v-divider>
          <v-card class="mb-4">
            <v-card-text class="">
              <NavUserObject ref="userObject"
                :receiveVar="sendVar" @eventReturn="getVarReturn">
              </NavUserObject>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item eager>
          <v-divider></v-divider>
          <v-card class="mb-4">
            <v-card-text class="">
              <NavGroupObject ref="groupObject"
                :receiveVar="sendVar" @eventReturn="getVarReturn">
              </NavGroupObject>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
        @click="updateObjectSecurity(vDataTable.data[0].object_skey, vDataTable.data[0].access_ind)">
        {{$t('ok')}}
      </v-btn>
      <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
        @click="
        var valueSend = [];
        sendToParentComponent(valueSend)">
        {{$t('cancel')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import NavUserObject from '@/components/navigation/NavUserObject'
  import NavGroupObject from '@/components/navigation/NavGroupObject'
  
  export default {
    components: {
      NavUserObject,
      NavGroupObject
    },
    props: ['receiveVar'],
    data () {
      return {
        sendVar: [],
        vDataTable: {
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
        accessTypeList: [
              { code: 0, description: 'No Restriction' },
              { code: 10, description: 'Disable' },
              { code: 20, description: 'Invisible' }],
      }
    },
    computed: {
      headers () {
        return [
          { text: 'Object', value: 'object_name', align: 'left' },
          { text: 'Object Type', value: 'object_type', align: 'left' },
          { text: 'Access', value: 'access_ind', align: 'left' },
        ]
      }
    },
    created: function () {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL)
      })
    },
    mounted: function () {
    },
    methods: {
      getVarReturn (value) {
        switch (value.childSend) {
        }
        // this.retrieveNodeRoute(process.env.VUE_APP_DB_APP_NAME)
      },
      sendToParentComponent (value) {
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      updateObjectSecurity (objectSkey, accessInd) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/updateNodeObject' + process.env.VUE_APP_DATABASE_TYPE, {
          object_skey: objectSkey,
          access_ind: accessInd
        })
          .then(response => {
            if (response.data.isSuccess) {
              var valueSend = []
              this.sendToParentComponent(valueSend)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      retrieveComponentObjectSecurity (appSkey, nodeSkey) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveComponentObjectSecurity' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: appSkey,
          node_skey: nodeSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
              if (this.vDataTable.data.length >= 1) {
                this.sendVar.objectSkey = this.vDataTable.data[0].object_skey
                var childUserObject = this.$refs.userObject
                childUserObject.retrieveUserObject(this.vDataTable.data[0].object_skey)
                var childGroupObject = this.$refs.groupObject
                childGroupObject.retrieveGroupObject(this.vDataTable.data[0].object_skey)
              }
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
</style>