<template>
  <div>
    <v-card outlined>
      <v-toolbar flat dense dark color="primary">
        <v-toolbar-title>Node Sequence</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="
          var valueSend = [];
          sendToParentComponent(valueSend)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          v-model="vDataTable.selected"
          :headers="headers"
          :items="vDataTable.data"
          :search="vDataTable.search"
          fixed-header
          height="400"
          :page.sync="vDataTable.options.page"
          :itemsPerPage.sync="vDataTable.options.itemsPerPage"
          :options.sync="vDataTable.options"
          :footer-props="{
            itemsPerPageText: $t('itemsPerPageText'),
            itemsPerPageAllText: $t('itemsPerPageAllText'),
            showFirstLastPage: true,
            showCurrentPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
          item-key="node_skey"
          class="fill-height">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.sort_seq="props">
            <v-edit-dialog :return-value.sync="props.item.sort_seq" large persistent>
              <div>{{ props.item.sort_seq }}</div>
              <template v-slot:input>
                <div class="mt-4 title">
                  Sort Seq
                </div>
                <v-text-field
                  v-model="props.item.sort_seq"
                  label="Edit"
                  type="number"
                  min="0"
                  step="any"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:no-data>
            <v-alert type="info">
              {{$t('noData')}}
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert type="warning">
              {{stringFormat($t('searchNotFound'),vDataTable.search)}}
            </v-alert>
          </template>
          <template v-slot:footer>
            <tr class="sticky-table-footer" style="position: Absolute;margin-top:10px">
              <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
                @click="
                  var valueSend = [];
                  valueSend.actionReturn = 'OK'
                  valueSend.dataSelected = vDataTable.data
                  sendToParentComponent(valueSend)">
              {{$t('ok')}}
              </v-btn>
              <v-divider class="mx-1" inset vertical></v-divider>
              <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize"
                @click="
                  var valueSend = [];
                  sendToParentComponent(valueSend)">
              {{$t('cancel')}}
              </v-btn>
            </tr>
          </template>
        </v-data-table> 
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default {
    props: ['receiveVar'],
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        vDataTable: {
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
      }
    },
    created: function () {
    },
    computed: {
      headers () {
        return [
          { text: 'Node Name', value: 'node_name', align: 'left' },
          { text: 'Sequence', value: 'sort_seq', align: 'left' },
        ]
      }
    },
    methods: {
      sendToParentComponent (value) {
        this.vDataTable.selected = []
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      retrieveNodeRouteFromNode (appSkey, fromNodeSkey) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveNodeRouteFromNode' + process.env.VUE_APP_DATABASE_TYPE, {
          app_skey: appSkey,
          from_node_skey: fromNodeSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
