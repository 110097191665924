<template>
  <div> 
    <v-dialog v-model="dialog.dialogAddUserObject" width="800" scrollable eager>
      <UserBrowse ref="addUser" max-width="800px"
        :receiveVar="sendVar" @eventReturn="getVarReturn">
      </UserBrowse>
    </v-dialog>
    <v-data-table dense
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      show-select
      fixed-header
      hide-default-footer
      height="200"
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="user_skey"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn outlined color="primary" class="mb-2" style="text-transform: capitalize" @click="dialog.dialogAddUserObject = true;$refs.addUser.retrieveUser()">
            <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>New User
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click="checkDeleteselected" style="text-transform: capitalize">
            <img src="../../assets/SoftwareClinicIcon/Delete.png" height="30" weight="30"/>Delete User
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" small class="mr-2" v-bind="attrs" v-on="on" @click="deleteItemConfirm(item)">delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.access_ind="{ item }">
        <v-select autocomplete dense
          :items="accessTypeList"
          item-value="code"
          item-text="description"
          v-model="item.access_ind"
          @change="editItem(item)">
        </v-select>
      </template>
      <template v-slot:no-data>
        <v-alert type="info">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
    </v-data-table>   
  </div>
</template>
<script>
  import UserBrowse from '@/components/common/UserBrowse'
  
  export default {
    components: {
      UserBrowse
    },
    props: ['receiveVar'],
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        sendVar: [],
        dialog: {
          dialogAddUserObject: false,
        },
        accessTypeList: [
              { code: 0, description: 'No Restriction' },
              { code: 10, description: 'Disable' },
              { code: 20, description: 'Invisible' }],
        vDataTable: {
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
      }
    },
    computed: {
      headers () {
        return [
          { text: '', align: 'center', value: 'data-table-select' },
          { text: this.$t('actions'), value: 'actions', sortable: false },
          { text: 'User', value: 'user_id', align: 'right' },
          { text: 'Name', value: 'full_name', align: 'left' },
          { text: 'Access', value: 'access_ind', align: 'left' },
        ]
      }
    },
    created: function () {
    },
    methods: {
      sendToParentComponent (value) {
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      getVarReturn (value) {
        switch (value.childSend) {
          case 'UserBrowse':
            this.dialog.dialogAddUserObject = false
            switch (value.actionReturn) {
              case 'OK':
                this.addUserObject(value.dataSelected)
            }
            break
        }
      },
      retrieveUserObject (objectSkey) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveUserObject' + process.env.VUE_APP_DATABASE_TYPE, {
          object_skey: objectSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      addUserObject (value) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/addUserObject' + process.env.VUE_APP_DATABASE_TYPE, {
          object_skey: this.receiveVar.objectSkey,
          to_user: value
        })
          .then(response => {
            if (response.data.isSuccess) {
              var valueSend = []
              this.sendToParentComponent(valueSend)
              this.retrieveUserObject(this.receiveVar.objectSkey)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      checkDeleteselected () {
        if (this.vDataTable.selected.length > 0) {
          this.$swal({
            text: 'คุณต้องการที่จะลบ User Object?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('yes'),
            cancelButtonText: this.$t('no')
          }).then((result) => {
            if (result) {
              this.deleteSelected()
            }
          })
        } else {
          this.$swal({type: 'error', title: 'กรุณาเลือก User ที่จะลบ'})
        }
      },
      async deleteSelected () {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')

        var del = []

        await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
          this.progressMessage = this.stringFormat('Deleting User {}', x.user_id)
          var val = await this.deleteUserObject(x)

          if (val !== undefined) {
            del.push(val)
          }
        }.bind(this))

        for (const x in del) {
          this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
        }

        this.dialog.dialogProgress = false
      },
      async deleteUserObject (item) {
        try {
          this.dialog.dialogProgress = true
          this.progressMessage = this.$t('waiting')
          var userSkey = item.user_skey
          var objectSkey = item.object_skey
          var valRet
          await this.axios.post(process.env.VUE_APP_API + '/navigation/deleteUserObject' + process.env.VUE_APP_DATABASE_TYPE, {
            user_skey: userSkey,
            object_skey: objectSkey
          })
            .then(async response => {
              if (response.data.isSuccess) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
                this.$swal({type: 'success', title: response.data.reasonText})
                valRet = item
              } else {
                await this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(async e => {
              await this.$swal({type: 'error', title: e.message})
            })
          return valRet
        } catch (e) {
          await this.$swal({type: 'error', title: e.message})
        } finally {
          this.dialog.dialogProgress = false
        }
      },
      async deleteItemConfirm (item) {
        this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
        this.vDataTable.editedItem = Object.assign({}, item)
        this.$swal({
          text: this.stringFormat('Deleting User {}', item.user_id),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then(async (result) => {
          if (result) {
            var val = await this.deleteUserObject(this.vDataTable.editedItem)
            if (val !== undefined) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
            }
            this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
            this.vDataTable.editedIndex = -1
          }
        })
      },
      async updateUserObject () {
        try {
          var userSkey = this.vDataTable.editedItem.user_skey
          var objectSkey = this.vDataTable.editedItem.object_skey
          var accessInd = this.vDataTable.editedItem.access_ind
          await this.axios.post(process.env.VUE_APP_API + '/navigation/updateUserObject' + process.env.VUE_APP_DATABASE_TYPE, {
            user_skey: userSkey,
            object_skey: objectSkey,
            access_ind: accessInd
          })
            .then(async response => {
              if (response.data.isSuccess) {
                await this.$swal({type: 'success', title: response.data.reasonText})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.$swal({type: 'error', title: e.message})
            })
        } catch (e) {
          this.$swal({type: 'error', title: e.message})
        } finally {
        }
      },
      editItem (item) {
        this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
        this.vDataTable.editedItem = Object.assign({}, item)
        this.updateUserObject()
      },
    }
  }
</script>
