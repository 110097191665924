<template>
  <div>
    <v-dialog v-model="dialog.dialogAddGroupObject" width="800" scrollable eager>
            <GroupBrowse ref="addGroup" max-width="800px"
              :receiveVar="sendVar" @eventReturn="getVarReturn">
            </GroupBrowse>
    </v-dialog>
    <v-dialog v-model="dialog.dialogDeleteGroupObject" persistent width="350" eager>
      <v-card>
        <p class="text-md-center"><v-icon style="font-size: 100px;" color="red">question_answer</v-icon></p>
        <v-card-text>
            <h3 class="headline mb-0"><b><p class="text-md-center">คุณต้องการที่จะลบ Group Object ?</p></b></h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mb-2"
            @click="deleteGroupObject()"
          >
          OK
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2"
            @click="dialog.dialogDeleteGroupObject = false"
          >
          Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.dialogDeleteSelectedGroupObject" persistent width="350" eager>
      <v-card>
        <p class="text-md-center"><v-icon style="font-size: 100px;" color="red">question_answer</v-icon></p>
        <v-card-text>
            <h3 class="headline mb-0"><b><p class="text-md-center">คุณต้องการที่จะลบ Group Object ที่เลือก ?</p></b></h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mb-2"
            @click="deleteSelectedGroupObject()"
          >
          OK
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2"
            @click="dialog.dialogDeleteSelectedGroupObject = false"
          >
          Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table dense
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      show-select
      fixed-header
      hide-default-footer
      height="200"
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="group_skey"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn outlined color="primary"class="mb-2" style="text-transform: capitalize" @click="dialog.dialogAddGroupObject = true;$refs.addGroup.retrieveGroup()">
            <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>New Group
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click.native="checkDeleteSelectedGroupObject" style="text-transform: capitalize">
            <img src="../../assets/SoftwareClinicIcon/Delete.png" height="30" weight="30"/>Delete Group
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" small class="mr-2" v-bind="attrs" v-on="on" @click="deleteItem(item)">delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.access_ind="{ item }">
        <v-select autocomplete dense
          :items="accessTypeList"
          item-value="code"
          item-text="description"
          v-model="item.access_ind"
          @change="editItem(item)">
        </v-select>
      </template>
      <template v-slot:no-data>
        <v-alert type="info">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import GroupBrowse from '@/components/common/GroupBrowse'
  
  export default {
    components: {
      GroupBrowse
    },
    props: ['receiveVar'],
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        sendVar: [],
        dialog: {
          dialogAddGroupObject: false,
          dialogDeleteGroupObject: false,
          dialogDeleteSelectedGroupObject: false,
        },
        accessTypeList: [
              { code: 0, description: 'No Restriction' },
              { code: 10, description: 'Disable' },
              { code: 20, description: 'Invisible' }],
        vDataTable: {
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
      }
    },
    computed: {
      headers () {
        return [
          { text: '', align: 'center', value: 'data-table-select' },
          { text: this.$t('actions'), value: 'actions', sortable: false },
          { text: 'Group', value: 'group_name', align: 'left' },
          { text: 'Access', value: 'access_ind', align: 'left' },
        ]
      }
    },
    created: function () {
    },
    methods: {
      sendToParentComponent (value) {
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      getVarReturn (value) {
        switch (value.childSend) {
          case 'GroupBrowse':
            this.dialog.dialogAddGroupObject = false
            switch (value.actionReturn) {
              case 'OK':
                this.addGroupObject(value.dataSelected)
            }
            break
        }
      },
      retrieveGroupObject (objectSkey) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/retrieveGroupObject' + process.env.VUE_APP_DATABASE_TYPE, {
          object_skey: objectSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      addGroupObject (value) {
        this.axios.post(process.env.VUE_APP_API + '/navigation/addGroupObject' + process.env.VUE_APP_DATABASE_TYPE, {
          object_skey: this.receiveVar.objectSkey,
          to_group: value
        })
          .then(response => {
            if (response.data.isSuccess) {
              var valueSend = []
              this.sendToParentComponent(valueSend)
              this.retrieveGroupObject(this.receiveVar.objectSkey)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      checkDeleteSelectedGroupObject () {
        if (this.vDataTable.selected.length > 0) {
          this.dialog.dialogDeleteSelectedGroupObject = true
        } else {
          this.$swal({type: 'error', title: 'กรุณาเลือก Group ที่จะลบ'})
        }
      },
      async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array)
        }
      },
      async deleteSelectedGroupObject () {
        await this.asyncForEach(this.vDataTable.selected, async function (x) {
          var groupSkey = x.group_skey
          var objectSkey = x.object_skey
          await this.axios.post(process.env.VUE_APP_API + '/navigation/deleteGroupObject' + process.env.VUE_APP_DATABASE_TYPE, {
            group_skey: groupSkey,
            object_skey: objectSkey
          })
            .then(async response => {
              if (response.data.isSuccess) {
                this.$swal({type: 'success', title: response.data.reasonText})
              } else {
                await this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(async e => {
              await this.$swal({type: 'error', title: e.message})
            })
        }.bind(this))

        this.dialog.dialogDeleteSelectedGroupObject = false
        this.retrieveGroupObject(this.receiveVar.objectSkey)
      },
      async deleteGroupObject () {
        try {
          var groupSkey = this.vDataTable.deletedItem.group_skey
          var objectSkey = this.vDataTable.deletedItem.object_skey
          await this.axios.post(process.env.VUE_APP_API + '/navigation/deleteGroupObject' + process.env.VUE_APP_DATABASE_TYPE, {
            group_skey: groupSkey,
            object_skey: objectSkey
          })
            .then(async response => {
              if (response.data.isSuccess) {
                this.vDataTable.data.splice(this.vDataTable.deletedIndex, 1)
                await this.$swal({type: 'success', title: response.data.reasonText})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.$swal({type: 'error', title: e.message})
            })
        } catch (e) {
          this.$swal({type: 'error', title: e.message})
        } finally {
          this.dialog.dialogDeleteGroupObject = false
        }
      },
      async updateGroupObject () {
        try {
          var groupSkey = this.vDataTable.editedItem.group_skey
          var objectSkey = this.vDataTable.editedItem.object_skey
          var accessInd = this.vDataTable.editedItem.access_ind
          await this.axios.post(process.env.VUE_APP_API + '/navigation/updateGroupObject' + process.env.VUE_APP_DATABASE_TYPE, {
            group_skey: groupSkey,
            object_skey: objectSkey,
            access_ind: accessInd
          })
            .then(async response => {
              if (response.data.isSuccess) {
                await this.$swal({type: 'success', title: response.data.reasonText})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.$swal({type: 'error', title: e.message})
            })
        } catch (e) {
          this.$swal({type: 'error', title: e.message})
        } finally {
        }
      },
      editItem (item) {
        this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
        this.vDataTable.editedItem = Object.assign({}, item)
        this.updateGroupObject()
      },
      deleteItem (item) {
        this.vDataTable.deletedIndex = this.vDataTable.data.indexOf(item)
        this.vDataTable.deletedItem = Object.assign({}, item)
        this.dialog.dialogDeleteGroupObject = true
      },
    }
  }
</script>
