var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"800","scrollable":"","eager":""},model:{value:(_vm.dialog.dialogAddUserObject),callback:function ($$v) {_vm.$set(_vm.dialog, "dialogAddUserObject", $$v)},expression:"dialog.dialogAddUserObject"}},[_c('UserBrowse',{ref:"addUser",attrs:{"max-width":"800px","receiveVar":_vm.sendVar},on:{"eventReturn":_vm.getVarReturn}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.vDataTable.data,"search":_vm.vDataTable.search,"show-select":"","fixed-header":"","hide-default-footer":"","height":"200","page":_vm.vDataTable.options.page,"itemsPerPage":_vm.vDataTable.options.itemsPerPage,"options":_vm.vDataTable.options,"footer-props":{
      itemsPerPageText: _vm.$t('itemsPerPageText'),
      itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    },"item-key":"user_skey"},on:{"update:page":function($event){return _vm.$set(_vm.vDataTable.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.$set(_vm.vDataTable, "options", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mb-2",staticStyle:{"text-transform":"capitalize"},attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.dialog.dialogAddUserObject = true;_vm.$refs.addUser.retrieveUser()}}},[_c('img',{attrs:{"src":require("../../assets/SoftwareClinicIcon/New.png"),"height":"30","weight":"30"}}),_vm._v("New User ")]),_c('v-divider',{staticClass:"mx-1",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticStyle:{"text-transform":"capitalize"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.checkDeleteselected}},[_c('img',{attrs:{"src":require("../../assets/SoftwareClinicIcon/Delete.png"),"height":"30","weight":"30"}}),_vm._v("Delete User ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search')},model:{value:(_vm.vDataTable.search),callback:function ($$v) {_vm.$set(_vm.vDataTable, "search", $$v)},expression:"vDataTable.search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"pink","small":""},on:{"click":function($event){return _vm.deleteItemConfirm(item)}}},'v-icon',attrs,false),on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"item.access_ind",fn:function(ref){
    var item = ref.item;
return [_c('v-select',{attrs:{"autocomplete":"","dense":"","items":_vm.accessTypeList,"item-value":"code","item-text":"description"},on:{"change":function($event){return _vm.editItem(item)}},model:{value:(item.access_ind),callback:function ($$v) {_vm.$set(item, "access_ind", $$v)},expression:"item.access_ind"}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.stringFormat(_vm.$t('searchNotFound'),_vm.vDataTable.search))+" ")])]},proxy:true}]),model:{value:(_vm.vDataTable.selected),callback:function ($$v) {_vm.$set(_vm.vDataTable, "selected", $$v)},expression:"vDataTable.selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }